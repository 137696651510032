<script setup lang="ts">
import { offerDialog } from '@/components/shared/OfferDialog/OfferDialogCompose'
import LogInBanner from '@/components/shared/LogInBanner.vue'

const { logOut, triggerApi, checkCart } = gamesUtils
const bannersOffers = ref<{ [key in Games.GameKeyType]?: Array<Games.Offer> }>({})
const bestDealsOffers = ref<{ [key in Games.GameKeyType]?: Array<Games.Offer> }>({})
const topOffers = ref<{ [key in Games.GameKeyType]?: Array<Games.Offer> }>({})

function loadGame(game: Games.GameItem) {
  if (game.api && game.active) {
    gamesUtils
      .apiGet(game.gameKey, 'offers/featured/')
      .then(({ data }) => {
        gamesUtils.setLoyalty(game.gameKey, data?.storeUser)
        if (data.bannerOffers) {
          bannersOffers.value[game.gameKey] = gamesUtils.renderApiOffers(data.bannerOffers, game.gameKey).offers
        }
        if (data.bestDeals) {
          bestDealsOffers.value[game.gameKey] = gamesUtils.renderApiOffers(data.bestDeals, game.gameKey).offers
        }
        if (data.topOffers) {
          topOffers.value[game.gameKey] = gamesUtils.renderApiOffers(data.topOffers, game.gameKey).offers
        }
        checkCart(
          [
            ...(bannersOffers.value[game.gameKey] ?? []),
            ...(bestDealsOffers.value[game.gameKey] ?? []),
            ...(topOffers.value[game.gameKey] ?? [])
          ],
          game.gameKey
        )
        if ((bestDealsOffers.value[game.gameKey]?.length || 0) < 2) {
          let usedIndex = 0
          if (topOffers.value[game.gameKey]?.length) {
            bestDealsOffers.value[game.gameKey] = bestDealsOffers.value[game.gameKey] ?? []
          }
          const bestDealsRefer = bestDealsOffers.value[game.gameKey]
          for (let i = 0; i < 2; i++) {
            const topOffer = topOffers.value[game.gameKey]?.[usedIndex]
            const bestDealOffer = bestDealsRefer?.[i]
            if (!bestDealOffer && topOffer && bestDealsRefer) {
              bestDealsRefer.push(topOffer)
              usedIndex++
            }
          }
        }
        if ((topOffers.value[game.gameKey]?.length || 0) < 4) {
          if (bestDealsOffers.value[game.gameKey]?.length || bannersOffers.value[game.gameKey]?.length) {
            topOffers.value[game.gameKey] = topOffers.value[game.gameKey] ?? []
          }
          const topOffersRefer = topOffers.value[game.gameKey]
          if (topOffersRefer) {
            const bestDealsRefer = bestDealsOffers.value[game.gameKey]
            if (bestDealsRefer) {
              topOffersRefer.push(...bestDealsRefer)
            }
            if (topOffersRefer.length < 4) {
              const bannersOffersRefer = bannersOffers.value[game.gameKey]
              if (bannersOffersRefer) {
                topOffersRefer.push(...bannersOffersRefer)
              }
            }
          }
        }
      })
      .catch((err) => {
        if (err === 401) {
          logOut(game.gameKey, err)
        } else {
          gamesUtils.catchError(err)
        }
      })
  }
}
function loadAllGames() {
  Object.entries(gamesUtils.list).forEach(([, game]) => {
    loadGame(game)
  })
}
onMounted(() => {
  loadAllGames()
  watch(triggerApi.triggerRef, () => {
    if (!offerDialog.value.visible) {
      loadAllGames()
    }
  })
})
</script>

<template>
  <div class="min-h-screen">
    <main-banner-component
      :offers="bannersOffers"
      class="mt-5"
    />
    <logos-cards-component class="mt-5" />
    <LogInBanner class="mt-2" />
    <best-deals-component
      :deals="bestDealsOffers"
      class="mt-5"
    />
    <top-offers-component :top-offers="topOffers" />
    <faq-component class="my-10" />
  </div>
</template>

<style scoped></style>
